import { createSelector } from "reselect";
import { Utils } from "revlock-webutils";

import {
    allCommissionSummary,
    allRevenueSummary,
    getCurrency
} from "./data.selector";
import { Currency } from "revlock-accounting";

const { CurrencyType } = Currency;

let defaultRevenueSummary = {
    byProduct: [],
    byCustomer: [],
    trend: {
        newBookings: {},
        earnedRevenue: {},
        periods: []
    },
    waterfall: {
        waterFall: {},
        periods: [],
        dataPointsCount: {}
    },
    KPI: {
        revenue: 0,
        deferredRevenue: 0,
        newBookings: 0
    }
};

let defaultCommissionSummary = {
    byProduct: [],
    byCustomer: [],
    trend: {
        amortized: {},
        deferred: {},
        periods: []
    },
    waterfall: {
        waterFall: {},
        periods: [],
        dataPointsCount: {}
    },
    KPI: {
        amortized: 0,
        deferred: 0,
        prior_amortized: 0,
        prior_deferred: 0,
        change_amortized: 0,
        change_deferred: 0
    }
};

export const revenueSummary = createSelector(
    [allRevenueSummary, getCurrency],
    (allRevenueSummary, currency) => {
        if (!allRevenueSummary) return defaultRevenueSummary;
        return (
            (currency && allRevenueSummary[currency.code]) ||
            defaultRevenueSummary
        );
    }
);

export const commissionSummary = createSelector(
    [allCommissionSummary, getCurrency],
    (allCommissionSummary, currency) => {
        if (!allCommissionSummary) return defaultCommissionSummary;
        return (
            (currency && allCommissionSummary[currency.code]) ||
            defaultCommissionSummary
        );
    }
);

export const getActiveCurrencies = createSelector(
    [allRevenueSummary],
    (allRevenueSummary) => {
        if (!allRevenueSummary) return undefined;

        let allCurrencyCodes = Object.keys(allRevenueSummary).filter(
            (key) => key !== "outdated"
        );
        return allCurrencyCodes.map(
            (currencyCode) => CurrencyType[currencyCode]
        );
    }
);

export const getRevenueByCustomer = createSelector(
    [revenueSummary],
    (revenueSummary) => {
        if (!revenueSummary) return undefined;
        return revenueSummary["byCustomer"] || defaultRevenueSummary.byCustomer;
    }
);

export const getRevenueByProduct = createSelector(
    [revenueSummary],
    (revenueSummary) => {
        if (!revenueSummary) return undefined;
        return revenueSummary["byProduct"] || defaultRevenueSummary.byProduct;
    }
);

export const getKPI = createSelector([revenueSummary], (revenueSummary) => {
    if (!revenueSummary) return undefined;
    return revenueSummary["KPI"] || defaultRevenueSummary.KPI;
});

export const getRevenueTrends = createSelector(
    [revenueSummary],
    (revenueSummary) => {
        if (!revenueSummary) return undefined;
        return revenueSummary["trend"] || defaultRevenueSummary.trend;
    }
);

export const getRevenueWaterFall = createSelector(
    [revenueSummary],
    (revenueSummary) => {
        if (!revenueSummary) return undefined;
        return revenueSummary["waterfall"] || defaultRevenueSummary.waterfall;
    }
);

export const outdated = createSelector(
    [allRevenueSummary],
    (allRevenueSummary) => {
        if (!allRevenueSummary) return undefined;
        return allRevenueSummary["outdated"];
    }
);

export const getHomeCurrency = (state) => {
    let toReturn;

    if (
        state.currentOrganization &&
        state.currentOrganization.currentOrganization
    ) {
        let currencyCode =
            state.currentOrganization.currentOrganization.currency;

        const be_id = state.currentOrganization.currentOrganization.be_id;

        if (be_id !== undefined) {
            for (const be of state.currentOrganization.currentOrganization
                .businessEntities) {
                if (be.Business_Entity_Id == be_id) {
                    currencyCode = be.Currency_Code;
                    break;
                }
            }
        }

        if (currencyCode) {
            toReturn = CurrencyType[currencyCode];
        }
    }

    // returning USD currency default if we couldn't find currency
    return toReturn || CurrencyType["USD"];
};

export const getCommissionByCustomer = createSelector(
    [commissionSummary],
    (commissionSummary) => {
        if (!commissionSummary) return undefined;
        return (
            commissionSummary["byCustomer"] ||
            defaultCommissionSummary.byCustomer
        );
    }
);

export const getCommissionByProduct = createSelector(
    [commissionSummary],
    (commissionSummary) => {
        if (!commissionSummary) return undefined;
        return (
            commissionSummary["byProduct"] || defaultCommissionSummary.byProduct
        );
    }
);

export const getCommissionKPI = createSelector(
    [commissionSummary],
    (commissionSummary) => {
        if (!commissionSummary) return undefined;
        return commissionSummary["KPI"] || defaultCommissionSummary.KPI;
    }
);

export const getCommissionTrends = createSelector(
    [commissionSummary],
    (commissionSummary) => {
        if (!commissionSummary) return undefined;
        return commissionSummary["trend"] || defaultCommissionSummary.trend;
    }
);

export const getCommissionWaterFall = createSelector(
    [commissionSummary],
    (commissionSummary) => {
        if (!commissionSummary) return undefined;
        return (
            commissionSummary["waterfall"] || defaultCommissionSummary.waterfall
        );
    }
);

export const currencyFormatter = createSelector(
    [getHomeCurrency],
    (currency) => {
        return (amount, n, x, sign) =>
            Utils.formatCurrency(amount, n, x, sign, currency.symbol);
    }
);
